import CommonUtil from '@/utils/common-util.js';
/**
 * Adobe 초기 설정 스크립트
 * (기존에 static/js/adobe/data-layer.js 에 있던것을 plugin으로 이관)
 */
// Adobe Launch Script 로드전에 adobeDataLayer가 정의되어 있어야 한다.
window.adobeDataLayer = window.adobeDataLayer || [];

if (CommonUtil.isProd()) {
  const scriptEl = document.createElement('script');
  scriptEl.src = 'https://assets.adobedtm.com/c953b70ef9d9/54a929adad7b/launch-84520d810790.min.js';
  scriptEl.defer = true;
  document.body.appendChild(scriptEl);
} else if (CommonUtil.isTest()) {
  const scriptEl = document.createElement('script');
  scriptEl.src = 'https://assets.adobedtm.com/c953b70ef9d9/54a929adad7b/launch-1294b7a16d07-development.min.js';
  scriptEl.defer = true;
  document.body.appendChild(scriptEl);
}

// 넷스루 솔루션 적용
if (CommonUtil.isTest()) {
  const installScriptEl = document.createElement('script');
  installScriptEl.src = 'https://ntm.samsungcard.biz/static/10001/install.js';
  installScriptEl.defer = true;
  document.body.appendChild(installScriptEl);
}
