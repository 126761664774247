/**
 * PFMAppInterface 정의
 */
import _ from 'lodash';
import BridgeCore from '../modules/bridge-core';
import URLParser, { isHppUrl, isSlfHmpgUrl, isSlfDirctUrl, isSsfHmpgUrl, isSsfDirctUrl } from '../modules/url-parser';
import bridgeUtil from '../modules/util.js';
import { setCacheData } from '@/utils/common-util';

const HANDLER_NAME = 'PFMAppInterface';

export default {
  HANDLER_NAME,

  /**
   * BridgeCore exec 호출 랩핑 함수
   * @param {String} apiName 브릿지 Api명
   * @param {Object} apiData 브릿지 Api 호출 데이터
   * @param {Function} apiCallback 콜백함수
   * @param {Boolean} preventDoubleClick 연속 호출 방지 플래그 (기본=false 로 연속 호출을 허용 함)
   */
  async exec(apiName, apiData, apiCallback, preventDoubleClick = false) {
    return await BridgeCore.exec(HANDLER_NAME, apiName, apiData, apiCallback, preventDoubleClick);
  },

  /**
   * 날짜 선택(년,월,일)
   *  년, 월,일을 선택할 수 있는 Native 화면 호출
   *
   * params : {
   *  title(string):제목
   *  default(string):초기값(YYYYMMDD)
   *  minDate(string):최소선택날짜(YYYYMMDD)
   *  maxDate(string):최대선택날짜(YYYYMMDD)
   *  }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  date(string):선택날짜
   * }
   */
  async getDateYmd(params, callbackFunc) {
    return await this.exec('getDateYmd', params, callbackFunc);
  },

  /**
   * 날짜 선택(년,월)
   *  년, 월을 선택할 수 있는 Native 화면 호출
   *
   * params : {
   *  title(string):제목
   *  default(string):초기값(YYYYMM)
   *  minDate(string):최소선택날짜(YYYYMM)
   *  maxDate(string):최대선택날짜(YYYYMM)
   *  }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  date(string):선택날짜
   * }
   */
  async getDateYm(params, callbackFunc) {
    return await this.exec('getDateYm', params, callbackFunc);
  },

  /**
   * 날짜 선택(년,월,min-max)
   *  년, 월을 선택할 수 있는 Native 화면 호출 (min-max 정상 동작 건)
   *
   * params : {
   *  title(string):제목
   *  default(string):초기값(YYYYMM)
   *  minDate(string):최소선택날짜(YYYYMM)
   *  maxDate(string):최대선택날짜(YYYYMM)
   *  }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  date(string):선택날짜
   * }
   */
  async getDateYmMinMax(params, callbackFunc) {
    return await this.exec('getDateYmMinMax', params, callbackFunc);
  },

  /**
   * 주기 선택
   *  주기를 선택할 수 있는 Native 화면 호출
   *
   * params : {
   *  title(string):제목
   *
   *  // 초기노출유형
   *  type(string):주기선택유형(M(월), W(주), O(1회))
   *
   *  // 반복 탭 화면 기본값
   *  monthDefault(string):월 선택 초기값(1~31)
   *  weekDefault(string):주 선택 초기값(0(일)~6(토))
   *
   *  // 1회 탭 화면 기본값
   *  onceDefault(string):초기값(YYYYMMDD)
   *  minDate(string):최소선택날짜(YYYYMMDD)
   *  maxDate(string):최대선택날짜(YYYYMMDD)
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  type(string):주기선택유형(M(월), W(주), O(1회))
   *  value(string):선택주기(M:1~31,W:0(일)~6(토),O:YYYYMMDD)
   * }
   *
   * ex)
   * 1. 반복 탭의 화면
   *  매달15일 -> data:{"result":true,"type":"M","value":"15"}
   *  매주수요일 -> data:{"result":true,"type":"W","value":"3"}
   *
   * 2. 1회 탭의 화면
   *  2021년 9월 11일 -> data:{"result":true,"type":"O","value":"20210911"}
   */
  async getPeriod(params, callbackFunc) {
    return await this.exec('getPeriod', params, callbackFunc);
  },

  /**
   * 카카오톡 공유
   *  카카오톡을 실행시켜 특정경로/이미지등을 공유한다.
   *
   * params : {
   *  1. feed
   *  [M] type(string):"feed"
   *  [M] content(content):메시지의 메인 콘텐츠 정보
   *  [O] buttons(button array):버튼 목록(최대 2개), 버튼 타이틀, 링크 변경 or 버튼 두개 넣을 때
   *
   *  2. text
   *  [M] type(string):"text"
   *  [M] text(string):텍스트 정보, 최대 200자
   *  [M] link(link):콘텐츠 클릭 시 이동할 링크 정보
   * }
   *
   * 공통 사용 객체
   * content : {
   *  [M] title(string):콘텐츠의 타이틀
   *  [M] imageUrl(string):콘텐츠의 이미지 URL
   *  [M] link(link):콘텐츠 클릭 시 이동할 링크 정보
   * }
   *
   * button : {
   *  [M] title(string):버튼의 타이틀
   *  [M] link(link):버튼클릭 시 이동할 링크 정보
   * }
   *
   * link : { // 파라미터 1개 이상 필수
   *  [M] webUrl(string):PC버전 카카오톡에서 사용하는 웹 링크 URL
   *  [M] mobileWebUrl(sring):모바일 카카오톡에서 사용하는 웹 링크 URL
   *  참고) URL의 도메인 부분은 [내 애플리케이션] > [플랫폼] > [Web]에서 등록한 사이트 도메인과 일치해야 함
   * }
   *
   * ex)
   * 1. feed
   * params : {
   *  type:"feed",
   *  contents:{
   *   title:'콘텐츠 제목',
   *   imageUrl:'콘텐츠 이미지 URL',
   *   link:{
   *    webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *    mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *   }
   *  },
   *  buttons:[
   *   {
   *    title:'버튼1의 타이틀',
   *    link:{
   *     webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *     mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *    },
   *    {
   *    title:'버튼2의 타이틀',
   *    link:{
   *     webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *     mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *    }
   *   }
   *  ]
   * }
   *
   * 2. text
   * params : {
   *  text:'텍스트 정보',
   *  link:{
   *   webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *   mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *  },
   *  buttons:[
   *   {
   *    title:'버튼1의 타이틀',
   *    link:{
   *     webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *     mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *    },
   *    {
   *    title:'버튼2의 타이틀',
   *    link:{
   *     webUrl:'PC버전 카카오톡에서 사용하는 웹 링크 URL',
   *     mobileWebUrl:'모바일 카카오톡에서 사용하는 웹 링크 URL'
   *    }
   *   }
   *  ]
   * }
   */
  async shareKakao(params, callbackFunc) {
    return await this.exec('shareKakao', params, callbackFunc);
  },

  /**
   * OS 공유
   *  OS자체 공유기능을 활성화한다.
   *
   * params : {
   *  text(string):공유할 문자열
   * }
   */
  async shareOs(params, callbackFunc) {
    return await this.exec('shareOs', params, callbackFunc);
  },

  /**
   * 클립보드 저장
   *  특정문자열을 클립보드에 저장한다.
   *
   * params : {
   *  data(string):클립보드에 저장할 문자열
   *  msg(string):저장 완료되었을 경우 표시할 메시지
   * }
   */
  async setClipBoard(params, callbackFunc) {
    return await this.exec('setClipBoard', params, callbackFunc);
  },

  /**
   * 클립보드 데이터 가져오기
   *  클립보드에 저장된 문자열을 꺼낸다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false,
   *  data(string):클립보드 data
   * }
   */
  async getClipBoard(params, callbackFunc) {
    return await this.exec('getClipBoard', params, callbackFunc);
  },

  /**
   * 보안키패드 호출(숫자)
   *  Nfilter 숫자보안키패드를 호출한다.
   *  각 업무 서비스에서 NFilter(NfilterService)를 활용하여 복호화처리할 것
   *
   * params : {
   *  length(number):입력받을 길이
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true - params 에서 전달한 길이만큼 입력된 경우
   *                  false - 입력받을 길이만큼 입력되지 않은 경우(입력한 길이만큼 UI 처리.)
   *  length(number):현재까지 입력된 길이
   *  pswde(string):암호화된 문자열
   * }
   */
  async secuKeypadNum(params, callbackFunc) {
    return await this.exec('secuKeypadNum', params, callbackFunc);
  },

  /**
   * 보안키패드 숨기기
   *  보안키패드를 숨긴다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   * }
   */
  async hideSecuKeypad(params, callbackFunc) {
    return await this.exec('hideSecuKeypad', params, callbackFunc);
  },

  /**
   * 휴대폰 인증
   *  KCB휴대폰 인증을 통한 본인확인을 진행한다.
   *  - 휴대폰 인증프로세스가 새로운 화면으로 진행
   *  - 호출횟수 제한 없음
   *
   * params : {
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *  mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *  srnId(string):화면ID
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  mnmCtfSn(string):모니모인증일련번호
   * }
   */
  async ctfMphSms(params, callbackFunc) {
    return await this.exec('ctfMphSms', params, callbackFunc);
  },

  /**
   * 본인인증(휴대폰/카드)
   *  Native 본인확인(휴대폰,카드)프로세스를 진행한다.
   *  - 인증프로세스가 새로운 Native 화면으로 진행
   *  - 호출횟수 제한 있음 (5회/일)
   *
   * params : {
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *  mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *  srnId(string):화면ID
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  mnmCtfSn(string):모니모인증일련번호
   * }
   */
  async ctfHsVd(params, callbackFunc) {
    return await this.exec('ctfHsVd', params, callbackFunc);
  },

  /**
   * FIDO 인증
   *  FIDO 인증을 통한 본인확인을 진행한다.
   *  - FIDO 인증이 바텀시트로 올라옴.
   *
   * params : {
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *  mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *  srnId(string):화면ID
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  mnmCtfSn(string):모니모인증일련번호(토큰 검증 후에 전달되는 값)
   * }
   */
  async ctfFido(params, callbackFunc) {
    return await this.exec('ctfFido', params, callbackFunc);
  },

  /**
   * 모니모 중요거래 인증서 여부 전달
   *  모니모에 저장되어 있는 모니모 인증서가 중요거래 인증서 인지 일반 거래 인증서 인지를 전달한다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  isImpCert(boolean):중요거래인증서 여부 [true: 중요거래 인증서, false: 일반거래 인증서]
   * }
   */
  async isImpMonimoCert(params, callbackFunc) {
    return await this.exec('isImpMonimoCert', params, callbackFunc);
  },

  /**
   * 계좌인증
   *  오픈뱅킹 가입 시 계좌1원인증을 하는 Native 화면으로 이동하여 인증을 진행합니다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   * }
   */
  async accAuth(params, callbackFunc) {
    return await this.exec('accAuth', params, callbackFunc);
  },

  /**
   * 전자서명
   *  FIDO인증 후 전자서명을 수행한다.
   *  - FIDO인증이 바텀시트로 올라옴.
   *
   * params : {
   *  mnmCertsGdDvC:모니모인증서등급구분코드(메타)
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *  mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *  srnId(string):화면ID
   *  elsgOtxtCn(string):전자서명원문 (JSONString)
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  mnmElsgSn(string):모니모전자서명일련번호(전자서명검증 후 리턴되는 값)
   * }
   */
  async elsg(params, callbackFunc) {
    return await this.exec('elsg', params, callbackFunc);
  },

  /**
   * 전자서명(관계사용)
   *  FIDO인증 후 전자서명을 수행한다.
   *  - FIDO인증이 바텀시트로 올라옴.
   *  - 전자서명검증 제외
   *
   * params : {
   *  mnmCertsGdDvC:모니모인증서등급구분코드(메타)
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *  mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *  srnId(string):화면ID
   *  elsgOtxtCn(string):전자서명원문 (JSONString)
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  elsgOtxtCne(string):전자서명원문내용암호화
   * }
   */
  async elsgAflcmp(params, callbackFunc) {
    return await this.exec('elsgAflcmp', params, callbackFunc);
  },

  /**
   * 전자서명(관계사축약)
   *  전자서명을 수행한다.
   *   - FIDO인증 SKIP
   *   - 전자서명검증 SKIP
   *   - ONLY 전자서명만 생성 후 리턴
   *   - 중요거래전자서명인데 일반거래인증서보유시 등급상향으로 이동 프로세스는 유지
   *
   * params : {
   *  mnmCertsGdDvC:모니모인증서등급구분코드(메타)
   *  elsgOtxtCn(string):전자서명원문 (JSONString)
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  elsgOtxtCne(string):전자서명원문내용암호화
   * }
   */
  async elsgComs(params, callbackFunc) {
    return await this.exec('elsgComs', params, callbackFunc);
  },

  /**
   * 로그인
   *  로그인 사용자 정보를 가져온다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  mnmCstMngtNo(string):모니모고객관리번호
   *  cstNm(string):고객명
   *  cstMngtNo(number):고객관리번호
   * }
   */
  async getLoginUser(params, callbackFunc) {
    return await this.exec('getLoginUser', params, callbackFunc);
  },

  /**
   * 로그아웃
   *  1. 관계사 웹앱에서 요청 시
   *  - 앱으로 로그아웃처리를 알림
   *  - 관계사코드에 따라 세션쿠키를 삭제처리
   *  2. 모니모 웹앱에서 요청 시
   *  - 로그아웃처리
   *
   * params : {
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  lgotRsonC(string):로그아웃사유코드(00:세션만료, 01:중복로그인)
   * }
   */
  async logout(params, callbackFunc) {
    return await this.exec('logout', params, callbackFunc);
  },

  /**
   * @deprecated 세션처리이슈로 기능삭제 2022.02.08 ~
   *
   * URL 오픈(현재브라우저)
   *  특정 URL을 현재 브라우저에서 OPEN
   *  - 모니모관계사코드에 따른 쿠키(세션) 제어포함
   *
   * params : {
   *  url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   * }
   */
  async openCurBrowser(params, callbackFunc, preventDoubleClick = true) {
    return await this.exec('openCurBrowser', params, callbackFunc, preventDoubleClick);
  },

  /**
   * URL 오픈(새 브라우저)
   *  특정 URL을 새로운 브라우저에서 OPEN
   *  - 모니모 관계사 코드에 따른 쿠키(세션) 제어포함
   *
   * params : {
   *  url(string): 이동할 URL(프로토콜 + 도메인포함 FULL URL)
   *  mnmAflcmpC(string): 모니모 관계사 코드(메타)
   *  headerYn(string): 헤더 포함 여부(기본값=Y)
   *  headerTitle(String): 헤더 타이틀명(headerYn: 'Y' && 관계사 화면이 아닐 때 해당 값을 웹뷰 헤더에 넣어줌)
   *  optionYn(String): 웹뷰 옵션 포함 여부(N: AOS 헤더 제거, IOS 푸터 새로고침,공유기능 비활성화)
   *  movingStepYn(string): 무빙 스탭 노출여부(Y: '관계사 이동 중' 화면이 표시됨)
   *  movingStepMsg(string): 무빙 스탭 메시지
   *  backYn(string): 뒤로가기 버튼 처리(삼성카드 소비분석 화면에서 사용)
   *  iosToolbarYn(string): ios에서 하단 툴바 노출여부(삼성카드 소비분석 화면에서 사용)
   *  selfClose(string): 함수 호출 이후 이전 webview를 닫을 수 있음
   * }
   */
  async openNewBrowser(params, callbackFunc, preventDoubleClick = true) {
    const reqParams = {
      mnmAflcmpC: '',
      headerYn: 'Y',
      headerTitle: '',
      optionYn: 'N',
      movingStepYn: 'N',
      movingStepMsg: '',
      backYn: 'N',
      iosToolbarYn: 'Y',
      selfClose: 'N',
      ...params,
    };
    // 초기 파라미터 추가
    const urlParser = new URLParser(reqParams.url);
    urlParser.addParam('firstYn', 'Y').addParam('useAppTitle', 'Y');
    // footer 노출이 필요한 화면들
    const footerShowSrns = ['UHPPFS0101M0.jsp'];
    const footerShow = footerShowSrns?.some(srnId => {
      return reqParams.url.includes(srnId);
    });
    if (!footerShow) {
      urlParser.addParam('footerShowYn', 'N');
    }
    // 헤더표시 Y && 카드 or 생명 or 화재 도메인인 경우
    if (reqParams.headerYn === 'Y') {
      if (
        (isHppUrl(reqParams.url) ||
          isSlfHmpgUrl(reqParams.url) ||
          isSlfDirctUrl(reqParams.url) ||
          isSsfHmpgUrl(reqParams.url) ||
          isSsfDirctUrl(reqParams.url)) &&
        reqParams.backYn === 'N'
      ) {
        reqParams.optionYn = 'Y';
      }
    } else {
      // 헤더표시 N 인 경우, optionYn N 처리
      reqParams.optionYn = 'N';
    }

    // 22.07.08 디채 대응 - 삼성카드(HPP)인 경우, 관계사코드 값 제거
    // 관계사코드 설정 시, AOS 기준 비로그인처리되는 이슈가 있어서 임의로 제거
    if (bridgeUtil.getMblOsDvC() === '01' && reqParams.mnmAflcmpC === 'HPP') {
      reqParams.mnmAflcmpC = '';
    }

    return await this.exec(
      'openNewBrowser',
      {
        ...reqParams,
        url: urlParser.toString(),
      },
      callbackFunc,
      preventDoubleClick,
    );
  },

  /**
   * URL 오픈(새브라우저 모달)
   *  특정 URL을 새로운 모달창에서 OPEN
   *  - 모니모관계사코드에 따른 쿠키(세션) 제어포함
   *
   * params : {
   *  url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   *  mnmAflcmpC(string):모니모관계사코드(메타)
   *  headerYn(string):헤더포함여부(기본값=Y)
   * }
   */
  async openNewModal({ url, mnmAflcmpC = '', headerYn = 'Y' }, callbackFunc) {
    return await this.exec(
      'openNewModal',
      {
        url: new URLParser(url).addParam('firstYn', 'Y').toString(),
        mnmAflcmpC,
        headerYn,
      },
      callbackFunc,
    );
  },

  /**
   * URL 오픈(아웃링크)
   *  특정 URL을 아웃링크로 OPEN
   *
   * params : {
   *  url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   * }
   */
  async openOutBrowser(params, callbackFunc, preventDoubleClick = true) {
    return await this.exec('openOutBrowser', params, callbackFunc, preventDoubleClick);
  },

  /**
   * 미션팝업 오픈
   *  미션달성 화면으로 이동할 수 있는 팝업화면 OPEN
   *
   * params : {
   *  url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   *  title(string):제목
   * }
   */
  async openMissionPopup({ url, title = '' }, callbackFunc) {
    return await this.exec(
      'openMissionPopup',
      { url: new URLParser(url).addParam('firstYn', 'Y').toString(), title },
      callbackFunc,
    );
  },

  /**
   * moveParam
   * @typedef {Object} moveParam 캐시의 VALUE : OpenNative에서 호출 후 이동을 정의한 파라미터
   * @property {String} target OpenNative에서 호출한 이동 화면
   * @property {String} type target 화면 이동 후 동작 유형 : '01' : 포커스(스크롤이동), '02' : 탭이동,
   *                                                        '03' : URL이동, '04' : 탭이동+포커스(스크롤이동),
   *                                                        '05' : 탭이동+URL이동, '06' : 탭이동+포커스(스크롤이동)+URL이동
   *                                                        '07' : 클릭
   * @property {String} id target 화면 이동 후 동작할 ID
   * @property {String} url target 화면 이동 후 이동할 URL(프로토콜+도메인포함 FULL URL)
   * @property {Number} expireTime 캐시 기간 만료 체크를 위한 UNIX_TIMESTAMP
   */
  /**
    * 앱 화면이동
    *  특정 앱화면으로 이동
    *
    * params : {
    *  [M]target(String):이동할 화면 이름
    *  [O]update(Boolean):화면 새로고침 여부
    *  [O]url(String):이동할URL(프로토콜+도메인포함 FULL URL)
    *  [O]selfClose(Boolean): 이동 전 현재 웹페이지 닫힘여부(default: N)
    *  [O]moveParams(Object) : {
    *    type(String) : target 화면 이동 후 동작 유형,
    *     // '01' : 포커스(스크롤이동), '02' : 탭이동, '03' : URL이동, '04' : 탭이동+포커스(스크롤이동), '05' : 탭이동+URL이동, '06' : 탭이동+포커스(스크롤이동)+URL이동, '07' : 클릭
    *    id(String)   : target 화면 이동 후 동작할 id,
    *    url(String)  : 이동할 URL(프로토콜+도메인포함 FULL URL),
    *  }

    * }
    *
    * target 종류
    * - main    : 메인
    * - notice  : 알림함
    * - setting : 설정
    * - profile : 프로필
    * - asset   : 내자산
    * - tutorial: 튜토리얼
    * - more    : GNB > 더보기
    * - today   : GNB > 투데이
    * - benefit : GNB > 혜택
    * - product:  GNB > 상품
    * - card    : GNB > 마이 > 카드
    * - life    : GNB > 마이 > 생명
    * - fire    : GNB > 마이 > 화재
    * - stock   : GNB > 마이 > 증권
    * - nice    : 신용조회
    * - health  : 걷기
    * - juniorToday: 주니어 투데이
    * - juniorMoney: 주니어 머니
    * - juniorPlay : 주니어 플레이
    * - juniorAll  : 주니어 전체
    */
  async openNative(params, callbackFunc) {
    const reqParams = {
      selfClose: 'N',
      ...params,
    };

    if (!_.isEmpty(params.moveParam)) {
      const expireIntervalTime = 10000; // unit: ms
      const moveParam = {
        ...params.moveParam,
        target: params.target,
        expireTime: Date.now() + expireIntervalTime,
      };

      setCacheData('OPENNATIVE_MOVE_PARAM', moveParam, true, true);
    }
    return await this.exec('openNative', reqParams, callbackFunc);
  },

  /**
   * 웹뷰 닫기
   *  현재브라우저를 닫는다.
   */
  async closeCurBrowser(params, callbackFunc) {
    return await this.exec('closeCurBrowser', params, callbackFunc);
  },

  /**
   * 전화앱 오픈
   *  단말 전화앱을 실행한다.
   *
   * params : {
   *  tno(string):전화번호
   * }
   */
  async openCallApp(params, callbackFunc) {
    return await this.exec('openCallApp', params, callbackFunc);
  },

  /**
   * 지도앱 오픈
   *  단말 지도앱을 실행한다.
   *
   * params : {
   *  latd(string):위도
   *  long(string):경도
   *  adr(string):주소
   * }
   */
  async openMapApp(params, callbackFunc) {
    return await this.exec('openMapApp', params, callbackFunc);
  },

  /**
   * 카드 홈페이지 서비스 호출
   *  카드 홈페이지 서비스를 호출한다.
   *  - CORS 등 브라우저에서 호출 시 제약사항 해결
   *  - 삼성카드 도메인으로
   *
   * params : {
   *  svId(string):서비스ID
   *  data(JSONString):서비스INPUT
   * }
   * callbackFunc(data)
   * data : { // data 그대로 전달
   *  // 서비스Response 그대로
   * }
   */
  async callHppService(params, callbackFunc) {
    // 네이티브로부터 응답받은 데이터에서 data 를 꺼내서 전달
    return (await this.exec('callHppService', params, callbackFunc))?.data ?? {};
  },

  /**
   * 디바이스 스토리지 저장
   *  특정데이터를 디바이스 스토리지에 저장한다.
   *  - 앱 재설치시, 같이 삭제되어도 무방
   *
   * params : {
   *  key(string):저장데이터 KEY
   *  value(string):저장데이터 VALUE
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   * }
   */
  async setDeviceStorage(params, callbackFunc) {
    return await this.exec('setDeviceStorage', params, callbackFunc);
  },

  /**
   * 디바이스 스토리지 조회
   *  디바이스 스토리지에 저장된 특정 데이터를 조회한다.
   *
   * params : {
   *  key(string):저장데이터 KEY
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  key(string):저장데이터 KEY
   *  value(string):저장데이터 VALUE
   * }
   */
  async getDeviceStorage(params, callbackFunc) {
    return await this.exec('getDeviceStorage', params, callbackFunc);
  },

  /**
   * 주소검색결과리턴
   *  Native에서 웹으로 주소검색을 요청했을때, 그결과를 다시 Native로 전달한다.
   *  Native는 해당브릿지를 받으면 주소검색화면 브라우저를 닫는다.
   *
   * params : {
   *  zip(string):우편번호
   *  ltn(string):지번
   *  pnadr(string):우편번호주소
   *  adbzip(string):우편번호외주소
   *  bldMngtNo(string):건물관리번호
   * }
   */
  async rtnNativeAddr(params, callbackFunc) {
    return await this.exec('rtnNativeAddr', params, callbackFunc);
  },

  /**
   * 약관동의여부결과리턴
   *  Native에서 웹으로 약관상세화면을 요청했을때, 그결과를 다시 Native로 전달한다.
   *  Native는 해당브릿지를 받으면 약관동의여부를 가져가고 해당화면을 닫는다.
   *
   * params : {
   *  mnmPrvGrpId(string):모니모약관그룹ID
   *  mnmPrvId(string):모니모약관ID
   *  prvAgYn(string):약관동의여부
   *  wconFomDvC(string):동의서형태구분코드
   * }
   */
  async rtnNativePrv(params, callbackFunc) {
    return await this.exec('rtnNativePrv', params, callbackFunc);
  },

  /**
   * 앱푸시 미수신건수 조회
   *  알림함에 앱푸시미수신건수를 조회한다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  nrcvMsgCnt(string):미수신메시지건수
   *  msg(string):가장최신메시지 toString() 결과 데이터
   * }
   */
  async getNrcvMsgCnt(params, callbackFunc) {
    return await this.exec('getNrcvMsgCnt', params, callbackFunc);
  },

  /**
   * 알림설정상태조회
   *  디바이스알림/설정알림 상태를 조회한다.
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  dvcNotySe(string):디바이스알림설정여부(Y/N)
   *  sappNotySe(string):앱알림설정여부(Y/N)
   * }
   */
  async getNotySeSts(params, callbackFunc) {
    return await this.exec('getNotySeSts', params, callbackFunc);
  },

  /**
   * 관계사채널/계약회원여부 업데이트
   *  관계사채널/계약회원여부가 갱신됐을때 앱으로 알려서 모니모DB가 최신화되게 처리한다.
   *
   * params: {
   *  aflcmpChnlCstYn(string):관계사채널고객여부,
   *  aflcmpCntrCstYn(string):관계사계약고객여부,
   *  mnmAflcmpC(string):모니모관계사코드,
   * }
   * callbackFunc(data)
   * data: {
   *  result(boolean):true/false
   * }
   */
  async updAflcmpMbSts(params, callbackFunc) {
    return await this.exec('updAflcmpMbSts', params, callbackFunc);
  },

  /**
   * 1Account로그인처리
   *  (카드한정)1Account로그인처리 후 ssotoken등 관련 쿠키를 관리한다.
   *   - 1Account로그인처리 암호화데이터 수신 후 처리
   *
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   * }
   */
  async prcsAflcmpLgn(params, callbackFunc) {
    return await this.exec('prcsAflcmpLgn', params, callbackFunc);
  },

  /**
   * FDS수집/전송
   *  FDS수집대상화면별 FDS수집정보를 전송한다.
   *
   *  1. FDS솔루션을 통한 기기정보수접
   *  2. 브릿지 파라미터로 넘어온 Data 와 FDS 로 수집한 데이터들
   *  3. /restapi/fds/insFds 호출
   *
   * params: {
   *  svId(string):서비스ID
   *  cdno(string):카드번호
   *  cdnoId(number):카드번호ID
   *  dsbAm(number):금액
   *  rrno(string):주민등록번호
   *  stlmMthC(string):결제방법코드
   * }
   *
   * 참고) 서비스ID 정보
   * .오픈뱅킹계좌/카드등록(오픈뱅킹등록완료화면진입) : 3600000502
   * .오픈뱅킹송금(인증호출전):3600000503 (금액 포함)
   * .카드번호조회(인증호출전):3600000504
   * .카드마이탭조회(화면진입시):3600000601
   * .모니모로그인(로그인 후):3700000601 (최종로그인후)
   * .모니머니충전(인증호출전):3700000303 (금액 포함)
   */
  async sendFDSClctInf(params, callbackFunc) {
    return await this.exec('sendFDSClctInf', params, callbackFunc);
  },

  /**
   * 파일다운로드
   *  파일다운로드를 처리한다.
   *
   *  파일명 필요 시
   *  브릿지 INPUT filename 참조
   *  브릿지 INPUT에 filename이 없으면 header참조
   *
   *  response header [Content-Disposition]의 filename을 parsing해서urldecoding
   *  ex) attachment;
   *      filename=%EC%9D%B4%EC%9B%90%EC%9A%B0_PC%EB%B3%B4%EC%95%88%EC%A4%80%EC%88%98%EC%84%9C%EC%95%BD%EC%84%9C.pdf"
   *
   * params: {
   *  url(string):파일다운로드 URL
   *  filename(string):파일명
   * }
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   * }
   */
  async fileDownload(params, callbackFunc) {
    return await this.exec('fileDownload', params, callbackFunc);
  },

  /**
   * 삼성카드통합앱오픈
   *  삼성카드통합앱(앱카드앱)을 실행한다.
   *
   * 브릿지가 호출되면 아래 Url 로 앱설치 유무 판단
   *  AOS: kr.co.samsungcard.mpocket
   *  IOS: com.samsungcard.mpocket
   *
   * 앱설치 X: 스토어 이동
   * 앱설치 O: monimo.appcard.samsungcard.com://{action}?{queryString} 실행
   *
   * params: {
   *  action(string):앱카드앱이 처리할 동작 정의(regCard:앱카드 등록)
   *  queryString(string):스키마 호출 시, 파라미터(ex. key=value1&key2=value2)
   * }
   */
  async openAppcard(params, callbackFunc) {
    return await this.exec('openAppcard', params, callbackFunc);
  },

  /**
   * 주식거래 주문 시, 단말정보(핸드폰번호, IP, MAC) 수집을 위해 호출한다.
   * ※단말정보 수집
   * 1. 전화번호
   * - AOS : 1순위 전화번호, 2순위 I + IP주소
   * - iOS : I + IP주소
   * 2. IP 주소
   *      브릿지 함수 호출 시점에 단말기 IP 리턴
   * 3. MAC 주소
   * - AOS : "A"+타입(P or I) + CRC32알고리즘조합값(단말정보)
   *  * 단말정보 : ①전화번호, ②구글광고ID, ③안드로이드ID
   *              (① →②→③순위로 구해서 있는 값으로 적용)
   *  * 타입 : P(전화번호), I(구글광고ID 또는 안드로이드ID)
   * - iOS : 공백
   * 4. 단말 ID 추가
   * - Android ID 또는 iOS UDID
   * params: N/A
   * callbackFunc(data)
   * data : {
   *   result: true/false,
   *   os(String):OS구분(AOS/iOS),
   *   phoneNo(String):핸드폰번호,
   *   ip(String):IP주소,
   *   mac(String):MAC주소,
   *   deviceId(String):Andoid ID 또는 iOS UDID
   * }
   */
  async getDeviceInfo(params, callbackFunc) {
    return await this.exec('getDeviceInfo', params, callbackFunc);
  },

  /**
   * 애드브릭스 - 탈퇴화면에서 DELETE API 및 이벤트 태깅
   * params: {
   *   mnmCstMngtNo(String):모니모고객관리번호,
   *   mnmCstWDtm() : 모니모탈퇴일시 (포맷 YYYYMMDDHH24MISS)
   * }
   */
  async mnmWithdraw(params, callbackFunc) {
    return await this.exec('mnmWithdraw', params, callbackFunc);
  },

  /**
   * 삼성카드 세션데이터 조회
   *  삼성카드 세션 및 중복로그인방지 값을 조회한다.
   *
   * params: N/A
   * callbackFunc(data)
   * data : {
   *  result(boolean):true/false
   *  scssn(string): 삼성카드 세션ID
   *  encst(string): 삼성카드 중복로그인방지 문자열
   * }
   */
  async getScssnData(params, callbackFunc) {
    return await this.exec('getScssnData', params, callbackFunc);
  },

  /**
   * 모니모 관계사 재로그인 요청
   *   모니모 및 관계사 세션이 끊어진 경우 재로그인을 요청한다.
   *   1. 안내메시지 표시
   *   2. 해당 관계사 재로그인 처리
   *
   * params: {
   *  mnmAflcmpC(string):모니모 관계사코드(PFM 포함),
   *  msgCn(string):메시지 내용
   * }
   * callbackFunc(data)
   * data: {
   *  result(boolean):true/false
   * }
   */
  async mnmAflcmpRLgnAk(params, callbackFunc) {
    return await this.exec('mnmAflcmpRLgnAk', params, callbackFunc);
  },

  /**
   *  관계사별 고객식별번호 암호화 값을 조회
   *   관계사별 고객식별번호 암호화 값을 조회한다.
   *
   * params: {
   *   mnmAflcmpC: 모니모 관계사 (SSL, SLF, SSS)
   * }
   * callbackFunc(data)
   * data: {
   *  result(boolean):true/false
   *  cstDrmNo(String): 고객식별번호 암호화 값
   * }
   */
  async getCstDrmNo(params, callbackFunc) {
    return await this.exec('getCstDrmNo', params, callbackFunc);
  },

  /**
   * 연락처 접근 권한 설정
   *  디바이스에 있는 연락처 접근 권한을 허용한다.
   *
   * 연락처 송금 부분에서 연락처 탭을 누르면 bridge API를 호출한다.
   *
   * params: {
   *  from(string): 탭 or 페이지 내부 버튼을 눌렀을때를 분기 처리하기 위한 파라미터
   * }
   *
   * callbackFunc(data)
   * data : {
   *   result(boolean):true/false 연락처 동의 됐는지 여부
   *   resultMsg(string):native 오류메시지가 있을때 리턴
   *   contactList(array): 연락처 리스트 (ex. {name:value,contact:value2})
   * }
   */
  async getContactList(params, callbackFunc) {
    return await this.exec('getContactList', params, callbackFunc);
  },

  /**
   *  관계사 계약/채널회원여부 재조회
   *   모니모 웹화면(혜택레벨 안내)에서 호출 시, 관계사* 코드값을 Native에 전달하여 해당 관계사의 계약/채널회원여부를 갱신 요청한다.
   *   웹화면 진입 시점에 계약/채널회원여부가 Y/Y가 아닌 관계사만 해당
   *   (Native는 요청받은 관계사에  대해 계약/채널회원여부조회 및 관계사회원여부수정(updAflCmpMbYn) 수행 후 성공/실패여부를 return)
   *
   * params: {
   *   mnmAflcmpCList(string array): 모니모 관계사 코드 목록[카드: HPP, 생명: SLF, 화재: SSF, 증권: SSS] 카드, 생명, 화재, 증권 순서에 맞춰서 전달
   *                   예) mnmAflcmpCList: [HPP, SSF, SSS] 카드 → 화재 → 증권 순서로 전달
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean):true(1개 이상 갱신성공) / false(전체 갱신실패)
   * }
   */
  async refreshAflcmpMbSts(params, callbackFunc) {
    return await this.exec('refreshAflcmpMbSts', params, callbackFunc);
  },
  /**
   * Cache 저장
   *  특정데이터를 암호화 하여 Cache에 저장한다.
   *  (모니모 로그인 시마다 초기화 된다.)
   *
   * params: {
   *   key(string): 저장데이터 key
   *   value(string): 저장데이터 value
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   * }
   */
  async setCacheData(params, callbackFunc) {
    return await this.exec('setCacheData', params, callbackFunc);
  },

  /**
   * Cache 조회
   *  특정 데이터를 조회한다.
   *
   * params: {
   *   key(string): 저장데이터 key
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   *   key(string): 저장데이터 key
   *   value(string): 저장데이터 value
   * }
   */
  async getCacheData(params, callbackFunc) {
    return await this.exec('getCacheData', params, callbackFunc);
  },

  /**
   * [관계사]SMS Hash 요청
   *  SMS 전송을 위한 모니모 Android의 Hash 값 요청
   *  - 삼성생명 보험계약대출 신청 화면에서 사용됨
   *  - 관련 이슈: UPFM-2842, PFM-3252
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result: true/false,
   *   hashValue: "dKf3cdFpE+o" // Hash 값 예시
   * }
   */
  async getSmsHash(params, callbackFunc) {
    return await this.exec('getSmsHash', params, callbackFunc);
  },

  /**
   * [관계사]SMS 인증번호 요청
   *  타이머 이내 SMS 인증번호 수신시 인증번호 반환
   *  - 삼성생명 보험계약대출 신청 화면에서 사용됨
   *  - 관련 이슈: UPFM-2842, PFM-3252
   *
   * params: {
   *   timerSec(string): SMS 인증요청 최대 대기시간
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   *   authNum(string): 수신한 SMS 인증번호
   * }
   */
  async requestSmsAuthNumber(params, callbackFunc) {
    return await this.exec('requestSmsAuthNumber', params, callbackFunc);
  },

  /**
   * 세션 장애 발생 시 호출
   *  프론트 공통에서 세션 장애 발생 시 호출하면 Native 팝업이 열리고 앱을 종료한다.
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   * }
   */
  async sessionExpired(params, callbackFunc) {
    return await this.exec('sessionExpired', params, callbackFunc);
  },

  /**
   * [관계사]QR스캔
   *  유비케어 SDK 를 통해 QR 스캔 하여 이미지정보로 전환 및 전달
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   errCode(String) : "0"(성공) / "1"(오류)
   *   base64ImageData(BinaryData) : 변환된 이미지의 base 64 인코딩된 바이너리 데이터
   * }
   */
  async openQrScan(params, callbackFunc) {
    return await this.exec('openQrScan', params, callbackFunc);
  },

  /**
   * [관계사]모니모ECID 전달
   *  삼성생명 모니모ECID 전달
   *   - 삼성생명 웹뷰 호출용
   *   - 어도비 ECID수신정보
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   *    mEcid(String) : 모니모 ECID
   * }
   */
  async getMonimoEcid(params, callbackFunc) {
    return await this.exec('getMonimoEcid', params, callbackFunc);
  },

  /**
   * [앱지원]인앱리뷰호출
   *  인앱 리뷰 api를 호출한다.
   *
   * 파라미터 및 상세스펙은 브릿지목록 컨플루언스 확인
   * URL: https://confluence.samsungcard.biz/display/IPFMDEVOPS/Bridge+List
   * 위치: 69행 callInAppReview
   */
  async callInAppReview(params, callbackFunc) {
    return await this.exec('callInAppReview', params, callbackFunc);
  },

  /**
   * 마이데이터 인증
   * 인증서 확인
   *  인증서 존재 여부 확인
   *
   * params: { N/A }
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   *   isCert(boolean) : 공동인증서 유무 (true/false)
   * }
   */
  async isPublicCert(params, callbackFunc) {
    return await this.exec('isPublicCert', params, callbackFunc);
  },

  /**
   * 마이데이터 인증
   * 전자서명데이터 전달
   *  인증서 호출/없는경우 메시지호출
   *
   * 파라미터 및 상세스펙은 브릿지목록 컨플루언스 확인
   * URL: https://confluence.samsungcard.biz/display/IPFMDEVOPS/Bridge+List
   * 위치: 63행 mydataCertSign
   */
  async mydataCertSign(params, callbackFunc) {
    return await this.exec('mydataCertSign', params, callbackFunc);
  },

  /**
   * 앱설치여부 확인
   *  인증전 앱설치 여부 확인
   *
   * params: {  appId(String): 인증앱명 (kakao, naver) }
   * callbackFunc(data)
   * data: {
   *   result(boolean):true/false
   *   isCheck(boolean):앱설치 여부 (true/false)
   * }
   */
  async isAppInstallCheck(params, callbackFunc) {
    return await this.exec('isAppInstallCheck', params, callbackFunc);
  },

  /**
   * 마이데이터
   * 단말내 데이터 조회
   *  - 암호화모듈은 AES256
   *  - 데이터 로딩시 저장된 데이터가 30일이 지난 데이터인경우 초기화
   *
   * params: {
   *   key(String):저장데이터KEY (MYDATA_MAIN_DATA)
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean):true/false
   *   key(String):저장데이터KEY
   *   value(String):저장데이터VALUE
   * }
   */
  async getEncLocalData(params, callbackFunc) {
    return await this.exec('getEncLocalData', params, callbackFunc);
  },

  /**
   * 마이데이터
   * 단말내 데이터 저장
   *  - 마이데이터 초화면의 데이터를 AOS-data store / IOS-user default 에 저장
   *  - keystore에 랜덤 키+사용자고유키일부를 salt로 활용하여 데이터 암복호화키 구성
   *  - 데이터 저장시 저장일자를 추가
   *  - 데이터 저장기간지정 default 30일
   *
   * params: {
   *   key(String):저장데이터KEY (MYDATA_MAIN_DATA)
   *   value(String):저장데이터VALUE
   *   dueDate(String):저장기간(Day)
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean):true/false
   * }
   */
  async setEncLocalData(params, callbackFunc) {
    return await this.exec('setEncLocalData', params, callbackFunc);
  },

  /**
   * 웹뷰 헤더 타이틀 설정
   *  열려있는 웹뷰의 헤더 타이틀을 동적으로 변경한다.
   *
   * params: {
   *   title(String): 웹뷰 헤더 타이틀명
   * }
   */
  async setHeaderTitle(params, callbackFunc) {
    return await this.exec('setHeaderTitle', params, callbackFunc);
  },

  /**
   * 증권 자체 인증 토근 저장
   *  모니모 접속 장애 시, 증권 자체 인증을 통한 주식 비상 주문 서비스
   *  제공을 위해, 증권 오픈API 서버에서 발급한 토큰 저장
   * ※ 앱 내 보안 영역에 저장 필요
   *    토큰별 사이즈 약 1,500 바이트
   *
   * params: {
   *   accessToken(String): 접근토큰,
   *   refreshToken(String): 리플레쉬 토큰
   * }
   * callbackFunc(data)
   * data: {
   *   result(boolean):true/false
   * }
   */
  async setStockTradeToken(params, callbackFunc) {
    return await this.exec('setStockTradeToken', params, callbackFunc);
  },

  /**
   * 증권 자체 인증 토근 조회
   *  모니모 접속 장애 시, 증권 자체 인증을 통한 주식 비상 주문 서비스
   *  제공을 위해, 증권 오픈API 서버에서 발급한 토큰 조회
   * ※ 앱 내 보안 영역에서 조회
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result(boolean):true/false,
   *   accessToken(String): 접근토큰,
   *   refreshToken(String): 리플레쉬 토큰
   * }
   */
  async getStockTradeToken(params, callbackFunc) {
    return await this.exec('getStockTradeToken', params, callbackFunc);
  },
  /**
   * AI검색 URL 오픈(새 브라우저)
   *  특정 URL을 새로운 브라우저에서 OPEN
   *  - 모니모 관계사 코드에 따른 쿠키(세션) 제어포함
   *
   * params : {
   *  AflShutdownYn(String): 화면차단정책 체크 여부
   *  AflLoginYn(String): 관계사 로그인 체크여부
   *  url(string): 이동할 URL(프로토콜 + 도메인포함 FULL URL)
   *  mnmAflcmpC(string): 모니모 관계사 코드(메타)
   *  headerYn(string): 헤더 포함 여부(기본값=Y)
   *  headerTitle(String): 헤더 타이틀명(headerYn: 'Y' && 관계사 화면이 아닐 때 해당 값을 웹뷰 헤더에 넣어줌)
   *  optionYn(String): 웹뷰 옵션 포함 여부(N: AOS 헤더 제거, IOS 푸터 새로고침,공유기능 비활성화)
   *  movingStepYn(string): 무빙 스탭 노출여부(Y: '관계사 이동 중' 화면이 표시됨)
   *  backYn(string): 뒤로가기 버튼 처리(삼성카드 소비분석 화면에서 사용)
   *  iosToolbarYn(string): ios에서 하단 툴바 노출여부(삼성카드 소비분석 화면에서 사용)
   *  selfClose(string): 함수 호출 이후 이전 webview를 닫을 수 있음
   * }
   */
  async openNewBrowserForAI(params, callbackFunc, preventDoubleClick = true) {
    const reqParams = {
      AflShutdownYn: 'Y',
      AflLoginYn: 'Y',
      mnmAflcmpC: '',
      headerYn: 'Y',
      headerTitle: '',
      optionYn: 'N',
      movingStepYn: 'N',
      backYn: 'N',
      iosToolbarYn: 'Y',
      selfClose: 'N',
      ...params,
    };
    // 초기 파라미터 추가
    const urlParser = new URLParser(reqParams.url);
    urlParser.addParam('firstYn', 'Y').addParam('useAppTitle', 'Y');
    // footer 노출이 필요한 화면들
    const footerShowSrns = ['UHPPFS0101M0.jsp'];
    const footerShow = footerShowSrns?.some(srnId => {
      return reqParams.url.includes(srnId);
    });
    if (!footerShow) {
      urlParser.addParam('footerShowYn', 'N');
    }
    // 헤더표시 Y && 카드 or 생명 or 화재 도메인인 경우
    if (reqParams.headerYn === 'Y') {
      if (
        (isHppUrl(reqParams.url) ||
          isSlfHmpgUrl(reqParams.url) ||
          isSlfDirctUrl(reqParams.url) ||
          isSsfHmpgUrl(reqParams.url) ||
          isSsfDirctUrl(reqParams.url)) &&
        reqParams.backYn === 'N'
      ) {
        reqParams.optionYn = 'Y';
      }
    } else {
      // 헤더표시 N 인 경우, optionYn N 처리
      reqParams.optionYn = 'N';
    }

    // 22.07.08 디채 대응 - 삼성카드(HPP)인 경우, 관계사코드 값 제거
    // 관계사코드 설정 시, AOS 기준 비로그인처리되는 이슈가 있어서 임의로 제거
    // if (bridgeUtil.getMblOsDvC() === '01' && reqParams.mnmAflcmpC === 'HPP') {
    //   reqParams.mnmAflcmpC = '';
    // }

    return await this.exec(
      'openNewBrowserForAI',
      {
        ...reqParams,
        url: urlParser.toString(),
      },
      callbackFunc,
      preventDoubleClick,
    );
  },
  /**
   * [오픈뱅킹]중요거래인증서발급
   *  중요거래 인증서 발급 진행한다.
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result(boolean): true/false
   * }
   */
  async getImpMonimoCert(params, callbackFunc) {
    return await this.exec('getImpMonimoCert', params, callbackFunc);
  },
  /**
   * 보안키패드 호출(문자)
   *  - NFilter 문자보안키패드를 호출한다.
   *
   * params: {
   *  length(Number):입력받을 길이
   *  crypto(String):암호화방식(“DEFAULT”, “RSA”)
   *  public_key(String):공개키
   * }
   * callbackFunc(data)
   * data: {
   *   result: true/false
   *   length(Number):현재까지입력된길이
   *   crypto(String):암호화방식
   *   pswde(String):암호화된문자열
   * }
   */
  async secuKeypadStr(params, callbackFunc) {
    return await this.exec('secuKeypadStr', params, callbackFunc);
  },
  /**
   * 시간선택(오전/오후, 시, 분)
   *  - 오전/오후, 시, 분을 선택할 수 있는 Native 화면 호출
   *  - Input 초기값과 output 선택시간 포맷은 웹에서 사용되는 일반적인 포맷을 따름
   *
   * params: {
   *  title(String):제목
   *  default (String):초기값(‘H:mm a’ or ‘H:mm p’)
   *  minutesInterval(Number):분 간격(1,5,10)
   * }
   * callbackFunc(data)
   * data: {
   *    result: true/false
   *    time(String):선택시간,(‘HH:mm a’ or ‘HH:mm p’)
   * }
   */
  async getTimeHm(params, callbackFunc) {
    return await this.exec('getTimeHm', params, callbackFunc);
  },
  /**
   * 건강 등급 산출을 위한 간편 인증 요청
   *  - GHC 를 통해 간편인증을 요청한다.
   *  - 선택 된 인증기관 앱에서 알림 요청
   *  - 인증 완료 후 모니모 앱으로 복귀(수동)
   *
   * params: {
   *  authItem(Int):본인인증 기관 선택(KAKAO:1, NAVER:3)
   *  gender(Int):성별(남성:1, 여성:2)
   *  smokingType(Int):흡연 기록(비흡연:1, 과거흡연:2, 흡연:3)
   *  medicalTreatment(Int): 3개월내 의료행위 여부(예:1, 아니요:2)
   *  birthDay(String):고객 생년월일(YYYYMMDD)
   *  userName(String):고객이름
   *  phoneNumber(String):고객 휴대전화번호
   *  stepCountList(Array):걸음수리스트 : [{
   *    step_date(String): 날짜(YYYY-MM-DD)
   *    step_count(Number): 걸음 수
   *  }]
   *  scoreHistoryList(Array):월별점수리스트 : [{
   *    month(Number): 달
   *    score(Number): 점수
   *  }]
   * }
   * callbackFunc(data)
   * data: {
   *    result: true/false
   *    statusCode(String):상태코드(에러코드 포함)
   * }
   */
  async reqGhcSimpleAuth(params, callbackFunc) {
    return await this.exec('reqGhcSimpleAuth', params, callbackFunc);
  },
  /**
   * 간편 인증 확인 및 건강 등급 산출
   *  - 간편 인증 결과를 확인하고 건강 등급 산출 결과를 얻는다.
   *  - reqSimpleAuth 로 인증 요청, 인증 처리가 완료된 후 호출되어야 함
   *
   * params: {N/A}
   * callbackFunc(data)
   * data: {
   *    result: true/false
   *    statusCode(String):상태코드(에러코드 포함)
   *    healthGrade(JSONString):건강등급 산출결과
   * }
   */
  async getGhctHealthGrade(params, callbackFunc) {
    return await this.exec('getGhctHealthGrade', params, callbackFunc);
  },
  /**
   * [관계사]OCR 촬영
   *  Mobile Scan 솔루션을 통한 문서 OCR 촬영 및 이미지 전환 전달
   *
   * params: {N/A}
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    data(json array): [{
   *        fileName(String) : 파일명,
   *        fileBase64String(String) : Base64 데이터 스트링,
   *        fileSize(String) : 파일 사이즈
   *    }]
   * }
   */
  async openOcrScan(params, callbackFunc) {
    return await this.exec('openOcrScan', params, callbackFunc);
  },
  /**
   * [관계사]FDS 정보 요청
   *  FDS 정보 및 약관동의 여부를 전달한다.
   *  - 약관동의 여부 체크 후 약관동의가 필요한 경우 약관화면을 띄우고 약관동의 저장 및 FDS 정보 전달
   *  - IpInside의 wData 전달
   *
   * params: {
   *  mnmAflcmpC(String):모니모관계사코드
   *  svId(String):FDS 서비스ID
   * }
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    prvAgYn(String):약관동의여부 (Y/N)
   *    fdsData(String): 암호화된 FDS wData
   * }
   */
  async getFDSInfo(params, callbackFunc) {
    return await this.exec('getFDSInfo', params, callbackFunc);
  },
  /**
   * [관계사]명의도용 (약관)정보 요청
   *  명의도용 약관동의 여부를 전달한다.
   *  - 약관동의 여부 체크 후 약관동의가 필요한 경우 약관화면을 띄우고 약관동의 저장 및 약관동의여부 전달
   *  - FakeFinder의 Fraud Detecton 정보 전달을 위햔 약관동의
   *
   * params: {
   *  mnmAflcmpC(String):모니모관계사코드
   * }
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    prvAgYn(String):약관동의여부 (Y/N)
   * }
   */
  async getFraudDetectInfo(params, callbackFunc) {
    return await this.exec('getFraudDetectInfo', params, callbackFunc);
  },
  /**
   * 간편모드 설정값 요청
   *  간편(고령자) 모드 설정값을 전달한다.
   *
   * params: {N/A}
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    simpleModeYn(String): 간편모드 설정여부 (Y/N)(default: N)
   * }
   */
  async getSimpleMode(params, callbackFunc) {
    return await this.exec('getSimpleMode', params, callbackFunc);
  },
  /**
   * 모니모페이 가입 여부 (10.4.0 이상)
   * 모니모페이 가입여부값을 Y/N 으로 받는다.
   * params: {
   *     N/A
   * }
   * callbackFunc(data)
   * data: {
   *     result: true/false
   *     regMonimoPayYn(string): 모니모페이 가입여부 (Y/N)
   * }
   */
  async isRegMonimoPay(params, callbackFunc) {
    return await this.exec('isRegMonimoPay', params, callbackFunc);
  },

  /**
   * 걸음수 동기화 요청
   *  요청 동기화일자의 삼성헬스/ ios 건강 걷기 데이터를 요청한다.
   *
   * params: {
   *  syncrnStrtdt(String): 동기화 시작일자(YYYYMMDD)
   *  syncrnEnddt(String): 동기화 종료일자(YYYYMMDD)
   * }
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    code(String):상태코드(에러코드포함)
   * }
   */
  async syncWalkingData(params, callbackFunc) {
    return await this.exec('syncWalkingData', params, callbackFunc);
  },

  /**
   * 걷기권한상태체크 요청
   *  걷기권한 상태 정보 전달 및 관련 팝업 노출
   *
   * params: {
   *  syncrnStrtdt(String): 동기화 시작일자
   *  syncrnEnddt(String): 동기화 종료일자
   * }
   * callbackFunc(data)
   * data: {
   *    result(boolean): true/false
   *    code(String):상태코드(에러코드포함)
   * }
   */
  async checkWalkingStatus(params, callbackFunc) {
    return await this.exec('checkWalkingStatus', params, callbackFunc);
  },

  /**
   * TTS 음성 파일 확인
   *
   * params: {
   *   fcstBrchId(string) 예보지점ID
   *   mnmTtsSvDvC(String): 모니모TTS서비스구분코드(ALL: 전체, 001: 증권브리핑, 002: 뉴스레터, 003: 산책지수)
   * }
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 응답결과
   *   exstYn(string): 파일존재여부,
   *   fileInfos: [{  // 음성파일정보응답DTO
   *     fileUrl(string): 파일경로
   *     imageFileUrl(string): 이미지파일경로
   *     title(string): 제목
   *   }]
   * }
   */
  async checkMediaFile(params, callbackFunc) {
    return await this.exec('checkMediaFile', params, callbackFunc);
  },

  /**
   * TTS 음성 파일 재생
   *
   * params: {
   *   fileInfos: [{  // 음성파일정보응답DTO
   *     fileUrl(string): 파일경로
   *     imageFileUrl(string): 이미지파일경로
   *     title(string): 제목
   *   }]
   * }
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 응답결과
   * }
   */
  async playMediaFile(params, callbackFunc) {
    return await this.exec('playMediaFile', params, callbackFunc);
  },

  /**
   * TTS 음성 파일 정지
   *
   * params: N/A
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 응답결과
   * }
   */
  async stopMediaFile(params, callbackFunc) {
    return await this.exec('stopMediaFile', params, callbackFunc);
  },

  /**
   * 모니모 법정대리인 인증 및 변경 요청
   *
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 인증결과
   * }
   */
  async isMonimoLgrpCert(params, callbackFunc) {
    return await this.exec('isMonimoLgrpCert', params, callbackFunc);
  },

  /**
   * 본인인증 휴대폰(주민등록번호 전체)
   *
   * params: {
   *   snrId: {
   *     phone: 휴대폰 약관ID,
   *     card: 카드 약관ID,
   *   },
   *   isSavePrvAgree: 약관동의 여부, Front에서 약관 동의 하면 Y, Native에서 약관 동의 하면 N,
   * }
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 인증결과 성공: true, 실패: false,
   * }
   */
  async ctfHsVdRrnoAll(params, callbackFunc) {
    return await this.exec('ctfHsVdRrnoAll', params, callbackFunc);
  },

  /**
   * 관계사회원가입약관동의
   *
   * callbackFunc(data)
   * data: {
   *   result(Boolean) 동의결과 성공: true, 실패: false
   * }
   */
  async agreeAflcmpMbPrv(params, callbackFunc) {
    return await this.exec('agreeAflcmpMbPrv', params, callbackFunc);
  },

  /**
   * 웹뷰 백그라운드 색상 적용
   *
   * params: {
   *   color: String  "FFFFFF", "000000" : (R, G, B) - "" : 공백, null, 6자리 아님 (초기 화면 원복)
   * }
   */
  async setBackgroundColor(params, callbackFunc) {
    return await this.exec('setBackgroundColor', params, callbackFunc);
  },

  /**
   * 이미지 다운로드
   * base64string으로 전달된 이미지를 기기 앨범에 저장한다.
   * params: {
   *     fileName(String):파일명(option)
   *     data(String):base64string
   * }
   * callbackFunc(data)
   * data: {
   *     result: true/false
   * }
   */
  async imageDownload(params, callbackFunc) {
    return await this.exec('imageDownload', params, callbackFunc);
  },

  /**
   * 약관 동의여부 및 약관화면ID 조회
   * 약관조회 ID를 받아 약관동의 여부 및 약관화면ID를 받아온다.
   * params: {
   *   sappTskDvId(String): 약관조회ID (ex:"PG030001")
   * }
   * callbackFunc(data)
   * data: {
   *   result(Boolean): true/false
   *   prvAgYn(String): 약관동의여부(Y/N)
   * }
   */
  async getPrvAgYn(params, callbackFunc) {
    return await this.exec('getPrvAgYn', params, callbackFunc);
  },
};
